import React, { useEffect, useState } from "react";

import "./Roles.css";
import Loading from "../../components/Loading/Loading";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import base64 from "base-64";
import Input from "../../components/Input/Input";
import TopRow from "../../components/TopRow/TopRow";
import TableV2 from "../../components/TableV2/TableV2";
import { Role, User } from "../../types/user";
import { Column } from "../../components/TableV2/TableV2";
import UserRoles from "../../components/UserRoles/UserRoles";

const Roles: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<User[]>([]);
  const [roleData, setRoleData] = useState<Role[]>([]);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    getUserData();
  }, []);

  function getUserData() {
    setLoading(true);
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/get-user-and-role-list",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        console.log(JSON.stringify(response));
        const responseData = response as { data: { data: { users: User[], roles: Role[] } } };
        setUserData(responseData.data.data.users);
        setRoleData(responseData.data.data.roles);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columns: Column<User>[] = [
    { title: 'License', field: 'username' },
    { title: 'Description', field: 'email' },
    {
      field: 'id',
      render: (row: User) => (
        <UserRoles user={row} />
      ),
    },
  ];

  return (
    <div className="background">
      <TopRow />
      {(
        <div id="content" className="content">
          <div className="table-container-icep">
            <div className="table-row-top-icep">
              <div className="table-header-icep">Users</div>
            </div>
            
              {loading ?
                <Loading />
                : userData.length === 0 ?
                  <div className="no-data">No Users found</div>
                  : <TableV2<User> data={userData} columns={columns} />
              }
          
          </div>
        </div>
      )}
    </div>
  );

};

export default Roles;

