import { RoleDictionary, User } from "../../types/user"
import RoleCheckBox from "./RoleCheckbox";

interface Props {
    user: User;
}

const UserRoles: React.FC<Props> = ({...props}) => {

    return (
	<div style={{width: 'fit', padding: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            {RoleDictionary.map((entry) => {
                return (
                    <RoleCheckBox
                    label={entry.label}
                    userId={props.user.id}
                    roleId={entry.type}
                    hasRole={props.user.roles.find((userRole) => userRole === entry.type) !== undefined}
                />
                );
            })}
        </div>
    );
}

export default UserRoles;
