import React, { useEffect } from "react";

import "./Upload.css";
import Loading from "../../components/Loading/Loading";
import upload from "../../assets/upload.svg";
import {
  useJsApiLoader,
} from "@react-google-maps/api";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import base64 from "base-64";
import { Table } from "../../components/Table/Table";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import TopRow from "../../components/TopRow/TopRow";


const Investigation: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  const [viewingVehicle, setViewingVehicle] = React.useState(false);

  const [jobs, setJobs] = React.useState<any[]>([]);
  const [isProcessing, setIsProcessing] = React.useState<Boolean>(false);

  useEffect(() => {
    const spinner = document.querySelector(
      "#processing-import-indicator"
    ) as HTMLSpanElement;
    if (spinner !== null) {
      if (isProcessing) {
        spinner.style.display = "block";
      } else {
        spinner.style.display = "none";
      }
    }
    // console.log("Processing:", isProcessing);
  }, [isProcessing]);

  useEffect(() => {
    if (jobs.length === 0) {
      return;
    }
    // console.log("Jobs:", jobs);
    checkUploadStatus();
  }, [jobs]);

  function performCSVUpload() {
    return () => {
      const btn = document.querySelector(
        "#upload-xlsx-button"
      ) as HTMLButtonElement;
      const fileInput: HTMLInputElement = document.querySelector(
        "#item-file-uploader"
      ) as HTMLInputElement;
      fileInput.click();
      fileInput.addEventListener("input", (event) => {
        const textContainer: string = btn.innerHTML;
        const target = event.target as HTMLInputElement;

        if (!target.files || target.files.length === 0) {
          return;
        }
        btn.innerHTML = btn.innerHTML.replace(" List", "ing...");
        setLoading(true);

        const file = target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
          "authentication",
          JSON.stringify({
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          })
        );

        console.warn('Uploading file ', formData);

        axios
          .post(
            "https://dev.cbva.co.za/v1/cbva/upload-vehicle-list",
            formData,
            {
              headers: {
                Authorization:
                  "Basic " +
                  base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
              },
            }
          )
          .then((response) => {
            const data = response.data;

            if (data.rows > 0) {
              setIsProcessing(true);
              // console.log("Is Processing:", isProcessing);
              setJobs(data.jobs);
            }

            setLoading(false);

            btn.innerHTML = textContainer;
          });

        fileInput.value = "";
      });
    };
  }

  async function checkUploadStatus() {
    await new Promise((r) => setTimeout(r, 3000));

    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/check-upload-status",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          data: jobs,
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        // console.log("Remaining Jobs:", response.data.remainingJobs);
        setJobs(response.data.remainingJobs);
        setIsProcessing(response.data.remainingJobs.length > 0);
        if (response.data.remainingJobs.length === 0) {
          getVehicleData();
        }
      });
  }

  let gettingVehicleData = false;

  function getVehicleData() {
    if (gettingVehicleData) {
      return;
    }
    gettingVehicleData = true;
    setLoading(true);
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/monitor-list-page",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          filters: {
            
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        const temp: React.SetStateAction<any[]> = [];
        response.data.data.transactions.forEach((transaction: any) => {
          const found = temp.find(
            (item) => item.deal_code === transaction.deal_code
          );
          if (found) {
            found.value += Number(transaction.value);
            found.risk_value += Number(transaction.risk_value);
          } else {
            temp.push(transaction);
          }
        });

        if (response.data.data.transactions.length > 0) {
          
        }
        setLoading(false);
        gettingVehicleData = false;
      })
      .catch((error) => {
        console.error(error);
        gettingVehicleData = false;
      });
  }



  return (
    <div className="background">
      {loading && <Loading />}
      
      
      

      <TopRow />

      {!viewingVehicle && (
        <div id="content" className="content">
          <div style={{ marginTop: "67px" }} className="second-row">
            <div className="filter-container-investigation">
              
              <div className="filter-buttons">
                
                
                <Button
                  className="filter-button filter-button-green"
                  id="upload-xlsx-button"
                  disabled={isProcessing ? true : false}
                  label={
                    <span
                      style={{
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={upload}
                        className="enlarge"
                        alt="upload"
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "0 auto",
                          color: "#fff",
                        }}
                      />
                      &nbsp; Import List
                    </span>
                  }
                  handleClick={performCSVUpload()}
                />
                <input
                  type="file"
                  name="item-file-uploader"
                  id="item-file-uploader"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          
        </div>
      )}

      
    </div>
  );
};

export default Investigation;
