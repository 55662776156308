import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading/Loading";

interface Props {
    token: string;
    vehicleId: number;
    imageName: string | null;
    documentName: string | null;
}

/**
 * Distinct from table view to account for view endpoint
 */
interface VehicleRecoveryView {
    id: number;
    guid: string;
    user_id: number;
    licence: string;
    gps: string | null;
    text: string;
    image_name: string;
    document_name: string;
    created_at: string;
}

const VehicleRecoveryView: React.FC<Props> = ({ ...props }) => {

    const [loading, setLoading] = useState<boolean>();
    const [vehicle, setVehicle] = useState<VehicleRecoveryView>();

    useEffect(() => {
        console.log("Props image name: " + props.imageName);
        setLoading(true);
        axios.post("https://dev.cbva.co.za/v1/cbva/vehicle-recovery-view", {
            authentication: { token: props.token },
            id: props.vehicleId
        })
            .then((response) => {
                const responseData = response as { data: { success: boolean, data: VehicleRecoveryView } };
                setVehicle(responseData.data.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);


    return (
        <div className="vehicle-details">
            {loading ?
                <Loading />
                : vehicle && <>
                    <div className="detail-row">
                        <strong>License:</strong> {vehicle.licence}
                    </div>
                    <div className="detail-row">
                        <strong>Description:</strong> {vehicle.text}
                    </div>
                    <div className="detail-row">
			 <strong>GPS Coordinates:</strong> {vehicle.gps ? vehicle.gps : "Unknown GPS Location"}
                    </div>
                    <div className="detail-row">
                        {props.imageName !== null && props.imageName !== undefined && vehicle.image_name &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                                <div><strong>Image</strong></div>
                                <div style={{ border: '1px solid white', borderRadius: '10px' }}>
                                    <img
                                        src={`https://dev.cbva.co.za/${vehicle.image_name}&token=${props.token}`}
                                        style={{ width: '250px', height: '250px', objectFit: 'cover', borderRadius: '10px' }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="detail-row">
                        {props.documentName !== null && props.documentName !== undefined && vehicle.document_name &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', padding: '0.2rem' }}>
                                <div><strong>Document</strong></div>
                                <a target="_blank" href={`https://dev.cbva.co.za/${vehicle.document_name}&token=${props.token}`}>{`View Document (Desktop Only)`}</a>
                            </div>
                        }
                    </div>
                </>}
        </div>
    );
}

export default VehicleRecoveryView;
