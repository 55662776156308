// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-container-spreadsheet-uploader {
    z-index: 2;
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    background-color: #ffffff;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1 1;
}

#uploader-vehicles_wrapper {
    margin-top: 10px;
    width: 100%;
    min-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Upload/Upload.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,4BAA4B;IAC5B,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,qBAAqB;IACrB,SAAO;AACX;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".filter-container-spreadsheet-uploader {\n    z-index: 2;\n    margin-left: 30px;\n    margin-right: 30px;\n    cursor: pointer;\n    width: fit-content;\n    height: fit-content;\n    text-align: center;\n    background-color: #ffffff;\n    padding: 20px 20px 20px 20px;\n    border-radius: 10px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-end;\n    flex: 1;\n}\n\n#uploader-vehicles_wrapper {\n    margin-top: 10px;\n    width: 100%;\n    min-height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
