export interface User {
    id: number;
    username: string;
    email: string;
    roles: RoleType[] | [],
    user: {
        id: number;
        username: string;
        email: string;
        roles: RoleType[] | []
    }
}

export enum RoleType {
    "Admin" = 1,
    "Investigation" = 2,
    "ICEP" = 3,
}

interface RoleDictionaryEntry {
    type: RoleType;
    label: string;
}

export const RoleDictionary: RoleDictionaryEntry[] = [
    { type: RoleType.Admin, label: 'Admin' },
    { type: RoleType.Investigation, label: 'Investigation' },
    { type: RoleType.ICEP, label: 'ICEP' },
];

export interface Role {
    id: RoleType;
    name: string;
}
