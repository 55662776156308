import React, { useEffect, useState } from "react";
import { ChangeEvent, FormEvent } from 'react';
import logo from "../../assets/logo.png";
import axios from 'axios';
import { Modal } from "../../components/Modal/Modal";
import "./VehicleRecovery.css";
import TableV2, { Column } from "../../components/TableV2/TableV2";
import VehicleRecoveryView from "./VehicleRecoveryView";

interface VehicleRecoveryTableData {
    id: number;
    guid: string;
    userId: number;
    licence: string;
    gps: string;
    text: string; // description
    image_name: string; // yii2 file location
    document_name: string; // yii2 file location
}

interface FormData {
    licence: string;
    text: string;
    gps: string;
}

interface FileData {
    uploadedImage: File | null;
    uploadedDocument: File | null;
}

interface FileMapping {
    [key: string]: string;
}

const VehicleRecovery: React.FC = () => {

    const defaultFormData = {
        licence: "",
        text: "",
        gps: "",
    };

    const defaultFileData = {
        uploadedImage: null,
        uploadedDocument: null
    }

    const [vehicles, setVehicles] = useState<VehicleRecoveryTableData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [showViewModal, setShowViewModal] = useState<boolean>(false);
    const [selectedVehicle, setSelectedVehicle] = useState<VehicleRecoveryTableData | null>(null);
    const [formData, setFormData] = useState<FormData>(defaultFormData);
    const [files, setFiles] = useState<FileData>(defaultFileData);
    const [loading, setLoading] = useState<boolean>(false);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [gpsString, setGpsString] = useState<string>();
    const [token, setToken] = useState<string>();

    useEffect(() => {
        //handle token   
        if (!token) {
            const urlParams = new URLSearchParams(window.location.search);
            const urlToken = urlParams.get('token');
            if (urlToken) {
                //we have token from app
                setToken(urlToken);
                localStorage.setItem('token', urlToken);
            } else {
                const storageToken = localStorage.getItem('token');
                if (storageToken) {
                    setToken(storageToken);
                }
            }
        }
        // //dev
        // const devToken: string = "";
        // setToken(devToken);

    }, []);

    // Fetch vehicles data
    useEffect(() => {
        if (token) {
            fetchVehicles();
        }
    }, [token]);

    useEffect(() => {
        getCurrentLocation()
            .then((gps) => {
                setGpsString(gps);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const fetchVehicles = () => {
        setLoading(true);
        setVehicles([]);
        axios.post('https://dev.cbva.co.za/v1/cbva/vehicle-recovery-index', {
            authentication: {
                token: token,
            }
        })
            .then((response) => {
                const responseData = response as { data: { data: VehicleRecoveryTableData[] } };
                console.table(responseData);
                setVehicles(responseData.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching vehicles:", error);
                setError("Failed to load vehicles. Please try again.");
                setLoading(false);
            });
    };


    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Map the file input names
        const fileMapping: FileMapping = {
            'image': 'uploadedImage',
            'document': 'uploadedDocument'
        };

        const yiiFieldName = fileMapping[e.target.name];
        const file = e.target.files ? e.target.files[0] : null;

        setFiles({
            ...files,
            [yiiFieldName]: file
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        if (token) {
            setFormLoading(true);
            e.preventDefault();

            // Create object for the entire form
            const submitData = new FormData();

            // Add text fields
            submitData.append('VehicleRecovery[licence]', formData.licence);
            submitData.append('VehicleRecovery[text]', formData.text);

            //image and document
            if (files.uploadedImage) {
                submitData.append('VehicleRecovery[uploadedImage]', files.uploadedImage);
            }

            if (files.uploadedDocument) {
                submitData.append('VehicleRecovery[uploadedDocument]', files.uploadedDocument);
            }

            if (gpsString) {
                submitData.append('VehicleRecovery[gps]', gpsString);
            }
            // else {
            //     //try to get from url
            //     const urlParams = new URLSearchParams(window.location.search);
            //     const location = urlParams.get('location');
            //     if(location){
            //         submitData.append('VehicleRecovery[gps]', location); 
            //     }
            // }

            //set user
            submitData.append('authentication[token]', token);

            axios.post('https://dev.cbva.co.za/v1/cbva/vehicle-recovery-create', submitData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((response) => {
                    const responseData = response as { data: { success: boolean, errors: any } };
                    if (!responseData.data.success) {
                        console.error(responseData.data.errors);
                        alert(responseData.data.errors);
                    } else {
                        console.log('Submission successful:', response.data);
                        fetchVehicles();
                        setFormData(defaultFormData);
                        setShowFormModal(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setFormLoading(false);
                });
        }
    };

    const getCurrentLocation = async (): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                console.error("Geolocation is not supported by this browser.");
                reject("Geolocation not supported");
                return;
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const gpsString = `${position.coords.latitude},${position.coords.longitude}`;
                    console.log("Current GPS:", gpsString);
                    setFormData({
                        ...formData,
                        gps: gpsString
                    });
                    resolve(gpsString);
                },
                (error) => {
                    console.error("Error getting location:", error);
                    reject(error);
                }
            );
        });
    };

    // Form modal component
    const FormModal = () => (
        <Modal
            isOpen={showFormModal}
            onClose={() => setShowFormModal(false)}
            title="Add Vehicle Recovery"
            size="large"
        >
            <form onSubmit={handleSubmit} className="vehicle-form">
                <div className="form-group">
                    <label htmlFor="licence">License Plate</label>
                    <input
                        type="text"
                        id="licence"
                        name="licence"
                        value={formData.licence}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="text">Description</label>
                    <textarea
                        id="text"
                        name="text"
                        value={formData.text}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="image">Vehicle Image</label>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleFileChange}
                        capture="environment"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="document">Document</label>
                    <input
                        type="file"
                        id="document"
                        name="document"
                        onChange={handleFileChange}
                    />
                </div>
                <div className="form-actions">
                    {/* <button type="button" onClick={() => getCurrentLocation()} className="location-button">
                        Get Current Location
                    </button> */}
                    <button type="submit" className="submit-button">{formLoading ? 'Submitting...' : 'Submit'}</button>
                </div>
            </form>
        </Modal>
    );

    // View modal component
    const ViewModal = () => {
        if (!selectedVehicle) {
            console.log("No Selected Vehicle");
            return null;
        }
        console.log("Selected Vehicle: " + JSON.stringify(selectedVehicle));
        return (
            <Modal
                isOpen={showViewModal}
                onClose={() => setShowViewModal(false)}
                title={`Vehicle Details: ${selectedVehicle.licence}`}
            >
                <VehicleRecoveryView
                    vehicleId={selectedVehicle.id}
                    imageName={selectedVehicle.image_name}
                    documentName={selectedVehicle.document_name}
                    token={token ?? ""}
                />
            </Modal>
        );
    };

    const handleView = (id: number) => {
        const vehicle = vehicles.find((v) => Number(v.id) === Number(id)) ?? null;
        setSelectedVehicle(vehicle);
        setShowViewModal(true);
    };

    const columns: Column<VehicleRecoveryTableData>[] = [
        { title: 'License', field: 'licence' },
        { title: 'Description', field: 'text' },
        {

            field: 'id',
            render: (row: VehicleRecoveryTableData) => (
                <button
                    onClick={() => handleView(row.id)}
                    style={{
                        backgroundColor: '#6c8df9',
                        border: '1px solid #6c8df9',
                        borderRadius: '10px',
                        padding: '0.5rem',
                        color: 'white',
                    }}>
                    View
                </button>
            ),
        },
    ];

    return (
        <div className="vehicle-recovery-container" style={{ height: '100%', overflowY: 'auto' }}>
            <div className="top-row"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '1rem',
                    padding: '1rem',
                }}>
                <div className="logo-container" style={{ margin: '0rem' }}>
                    <img src={logo} className="logo" alt="logo" />
                </div>

                <div className="action-container">
                    <button
                        className="filter-button filter-button-blue"
                        onClick={() => setShowFormModal(true)}
                    >
                        Add Vehicle
                    </button>
                </div>

                <div className="table-container" style={{ padding: '1rem', width: '90%' }}>
                    <div className="table-header">Vehicles</div>
                    {loading ?
                        <div className="loading">Loading vehicles...</div>
                        : vehicles.length === 0 ?
                            <div className="no-data">No vehicles found</div>
                            : <TableV2<VehicleRecoveryTableData> data={vehicles} columns={columns} />
                    }
                </div>
            </div>

            {/* Render modals */}
            {showFormModal && FormModal()}
            {showViewModal && ViewModal()}
        </div>
    );
};

export default VehicleRecovery;
