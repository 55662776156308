
import React, { useEffect, useRef, useState } from "react"
import { RoleType } from "../../types/user";
import Checkbox from "../Checkbox/Checkbox";
import CheckboxTicked from "../../assets/CheckboxTicked.svg";
import CheckboxUnticked from "../../assets/CheckboxUnticked.svg";
import axios from "axios";

interface Props {
    hasRole: boolean;
    roleId: RoleType;
    userId: number;
    label: string;
}

const RoleCheckBox: React.FC<Props> = ({ ...props }) => {

    const [checked, setChecked] = useState<boolean>(props.hasRole);

    const handleChange = () => {
        if(checked){
            //remove
            console.log("Removing role...");
            axios.post(`https://dev.cbva.co.za/v1/user/remove-role?roleId=${props.roleId}`, {
                authentication: {
                    token: localStorage.getItem("token"),
                    refresh: localStorage.getItem("refreshToken"),
                  },
            }).then((response) => {

                console.log(response);
                const responseData = response as {data: {success: boolean, errors: any}};
                if(responseData.data.success === true){
                    setChecked(!checked);
                } else {
                    alert("Error removing role! See console.");
                    console.error(responseData.data.errors);
                }

            }).catch((error) => {
                console.error(error);
            });
        } else {
            //add
            console.log("Adding role...");
            axios.post(`https://dev.cbva.co.za/v1/user/add-role?roleId=${props.roleId}`, {
                authentication: {
                    token: localStorage.getItem("token"),
                    refresh: localStorage.getItem("refreshToken"),
                  },
            }).then((response) => {
                console.log(response);
                const responseData = response as {data: {success: boolean, errors: any}};
                if(responseData.data.success === true){
                    setChecked(!checked);
                } else {
                    alert("Error adding role! See console.");
                    console.error(responseData.data.errors);
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    return (
        <div style={{display: 'flex', gap: '0.2rem'}}>
            <p>{props.label}</p>
            <Checkbox
            checked={checked}
            handleChange={() => { handleChange(); }}
            imageChecked={CheckboxTicked}
            imageUnChecked={CheckboxUnticked}
            onChangeDrop={() => { }}
            />
        </div>
    );
}

export default RoleCheckBox;
