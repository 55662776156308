import React from "react";
import logo from "../../assets/logo.png";
import profile from "../../assets/profile.png";
import { MenuDictionary } from "../../types/menu";
import { RoleDictionary } from "../../types/user";

const TopRow = () => {
  const [userRoles] = React.useState<any[]>(
    JSON.parse(localStorage.getItem("roles") || "[]")
  );
  const [rolesOpen, setRolesOpen] = React.useState(false);

  return (
    <div className="top-row">
      <div className="logo-container">
        <img src={logo} className="logo" alt="logo" />
      </div>
      <div>
        <div
          className="profile-container"
          onClick={() => setRolesOpen(!rolesOpen)}
        >
          <img src={profile} className="profile" alt="profile" />
          <div className="profile-text">{rolesOpen ? ">" : "<"}</div>
        </div>
        {rolesOpen && (
          <div className="roles-container">
            {MenuDictionary.map((menuItem, i) => {
              console.log("Menu item roles: " + menuItem.roles);
              console.log("User Roles: " + userRoles);
              if (menuItem.roles.some(role => {
                const label = RoleDictionary.find((entry) => entry.type === role)?.label;
                return userRoles.includes(label);
              })) {
                return (
                  <div
                    key={i}
                    className="role"
                    onClick={() => {
                      setRolesOpen(false);
                      window.location.href = menuItem.href;
                    }}
                  >
                    {menuItem.label}
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopRow;

