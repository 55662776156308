import { Role, RoleType } from "./user";

interface MenuDictionaryEntry {
    label: string;
    href: string;
    roles: RoleType[];
}

export const MenuDictionary: MenuDictionaryEntry[] = [
    { label: 'Dashboard', href: '/dashboard', roles: [RoleType.Admin] },
    { label: 'ICEP User', href: '/icep', roles: [RoleType.ICEP] },
    { label: 'Investigation User', href: '/investigation', roles: [RoleType.Investigation] },
    { label: 'Vehicle Recovery', href: '/vehicle-recovery', roles: [RoleType.Admin] },
    { label: 'Roles', href: '/roles', roles: [RoleType.Admin] },
];
