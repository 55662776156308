import React from 'react';

export type Column<T> = {
  title?: string;
  field: keyof T; 
  render?: (row: T) => React.ReactNode; // easy peasy way
};

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
}

function TableV2<T>({ data, columns }: TableProps<T>) {
  return (
    //just yoinking styles from vehicle table
    <table className='vehicle-data-table'> 
      <thead>
        <tr>
          {columns.map((col, index) => (
            <th key={index}>{col.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((col, colIndex) => (
              <td key={colIndex}>
                {col.render ? col.render(row) : String(row[col.field])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TableV2;

